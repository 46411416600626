<template>
  <div class="mb-2">
    <div class="sm:flex">
      <div>
        <vxe-radio-group class="custom-btn-group" v-model="type" size="small">
          <vxe-radio-button label="d" content="日"></vxe-radio-button>
          <vxe-radio-button label="m" content="月"></vxe-radio-button>
          <vxe-radio-button label="w" content="週"></vxe-radio-button>
          <vxe-radio-button label="c" content="自訂"></vxe-radio-button>
        </vxe-radio-group>
      </div>
      <div>
        <vxe-input size="small" v-show="type === 'm'" v-model="input.month" placeholder="請選擇月份" type="month"
          valueFormat="yyyy-MM" class="rounded-pill" transfer></vxe-input>
        <vxe-input size="small" v-show="type === 'd'" v-model="input.date" placeholder="請選擇日期" type="date"
          valueFormat="yyyy-MM-dd" class="rounded-pill" transfer></vxe-input>
        <vxe-input size="small" v-show="type === 'w'" v-model="input.week" placeholder="請選擇週" type="week"
          valueFormat="yyyy-MM-dd" class="rounded-pill" transfer></vxe-input>
        <!-- <WeekSelect
        v-show="type === 'w'"
        v-model="input.week"
        class="d-inline-block"
        :placeholder="$t('input.week')"
        valueFormat="yyyy-MM-dd"
      ></WeekSelect> -->
        <span v-show="type === 'c'">
          <span>
            <vxe-input size="small" v-model="input.range.start" placeholder="請選擇開始日期" type="date"
              valueFormat="yyyy-MM-dd" class="rounded-pill" transfer></vxe-input>
          </span>
          <span class="px-1">~</span>
          <span>
            <vxe-input size="small" v-model="input.range.end" placeholder="請選擇結束日期" type="date" valueFormat="yyyy-MM-dd"
              class="rounded-pill" transfer></vxe-input>
          </span>
        </span>
      </div>
    </div>
    <div v-if="triggerType === 'button'">
      <vxe-button status="primary" icon="simple-icon-magnifier" round @click="query" size="small">搜尋</vxe-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, PropType, watch } from "vue";
import {
  toStringDate as toDate,
  toDateString as toString,
} from "xe-utils/date";

type QueryDate = {
  startDate?: string | Date;
  endDate?: string | Date;
};

export default defineComponent({
  props: {
    dateType: {
      type: String as PropType<"m" | "d" | "w" | "c">,
      default: "d"
    },
    returnAsStirng: {
      type: Boolean,
      default: false
    },
    triggerType: {
      type: String as PropType<"immediate" | "button">,
      default: "button"
    },
    startDate: [String, Date],
    endDate: [String, Date],
  },
  emits: ["query"],
  setup(props, { emit }) {
    const type = ref(props.dateType);
    const input = reactive({
      date: "",
      month: "",
      week: "",
      range: {
        start: props.startDate,
        end: props.endDate,
      },
    });

    const queryData = reactive<QueryDate>({
      startDate: "",
      endDate: "",
    });

    const getDate = () => {
      switch (type.value) {
        case "d":
          if (props.returnAsStirng) {
            queryData.startDate = input.date;
            queryData.endDate = input.date + ' 23:59:59'
          } else {
            queryData.startDate = toDate(input.date);
            queryData.endDate = new Date(queryData.startDate.getTime());
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "m":
          if (props.returnAsStirng) {
            const date = toDate(input.month + "/01");
            queryData.startDate = toString(date, "yyyy/MM/dd")
            queryData.endDate = toString(date, "yyyy/MM/dd 23:59:59")
          } else {
            queryData.startDate = toDate(input.month + "/01");
            queryData.endDate = new Date(queryData.startDate.getTime());
            queryData.endDate.setMonth(queryData.endDate.getMonth() + 1);
            queryData.endDate.setDate(-1);
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "w":
          if (props.returnAsStirng) {
            queryData.startDate = input.week;
            const date = toDate(input.week);
            date.setDate(date.getDate() + 6);
            queryData.endDate = toString(date, "yyyy/MM/dd 23:59:59")
          } else {
            queryData.startDate = toDate(input.week);
            queryData.endDate = toDate(input.week);
            queryData.endDate.setDate(queryData.endDate.getDate() + 6);
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "c":
          queryData.startDate = props.returnAsStirng
            ? input.range.start
            : toDate(input.range.start);
          if (props.returnAsStirng) {
            queryData.startDate = input.range.start;
            queryData.endDate = input.range.end + ' 23:59:59'
          } else {
            queryData.startDate = toDate(input.range.start);
            queryData.endDate = toDate(input.range.end);
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
      }
    };

    const query = () => {
      getDate();
      emit("query", queryData);
    };

    if (props.triggerType === "immediate") {
      watch(input, () => query());
    }

    return {
      type,
      input,
      query
    }
  }
})

</script>
